import type {
  ServiceAggregatedPreProcessType,
  ServiceSearchAggregatedType,
} from '../../aggregated/services'
import {
  getServiceAggregatedPreProcess,
  getServiceSearchAggregated,
} from '../../aggregated/services'
import type { ServiceXPropertyType } from '../../services/xProperty'
import { getServiceXProperty } from '../../services/xProperty'
import type { AppType, UserType } from '../context/FoundationProvider'
import type {
  ServiceSearchCommunityType,
  ServiceV4CommunityType,
} from '../../community/services'
import {
  getServiceSearchCommunity,
  getServiceV4Community,
} from '../../community/services'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import type { ServiceKarrotMarketType } from '../../flea-market/services'
import { getServiceKarrotMarket } from '../../flea-market/services'
import type { ServiceUserType } from '../../user/services'
import { getServiceUser } from '../../user/services'
import type { ServiceSearchBizPostType } from '../../business/services'
import { getServiceSearchBizPost } from '../../business/services'
import type { ServiceCategory } from '../../category/services'
import { getServiceCategory } from '../../category/services'
import type { ServiceAutoCompleteType } from '../../auto-complete/services'
import { getServiceAutoComplete } from '../../auto-complete/services'
import type { ServiceSearchFleaMarketType } from '../../flea-market/services/ServiceSearchFleaMarket'
import { getServiceSearchFleaMarket } from '../../flea-market/services/ServiceSearchFleaMarket'
import type { ServiceRecentSearchType } from '../../recent-search/services'
import { getServiceRecentSearch } from '../../recent-search/services'
import type { ServiceSearchPlaceType } from '../../place/services/ServiceSearchPlace'
import { getServiceSearchPlace } from '../../place/services/ServiceSearchPlace'
import type { ServiceKeywordNotificationType } from '../../keyword-notification/services'
import { getServiceKeywordNotification } from '../../keyword-notification/services'

import type { ServiceV4KarrotmarketType } from '../../flea-market/services/ServiceV4Fleamarket'
import { getServiceV4Fleamarket } from '../../flea-market/services/ServiceV4Fleamarket'
import type { ServiceHotKeywordsType } from '../../hot-keyword/services/ServiceHotKeywords'
import { getServiceHotKeywords } from '../../hot-keyword/services/ServiceHotKeywords'

export interface MyContext {
  app: AppType
  user: UserType
  experiment: SearchExperimentReferrerType
  regionId?: number
  services: {
    hotKeywords: ServiceHotKeywordsType
    karrotMarket: ServiceKarrotMarketType
    xProperty: ServiceXPropertyType
    autoComplete: ServiceAutoCompleteType
    category: ServiceCategory
    aggregated: ServiceSearchAggregatedType
    aggregatedPreProcess: ServiceAggregatedPreProcessType
    fleaMarket: ServiceSearchFleaMarketType
    v4Fleamarket: ServiceV4KarrotmarketType
    community: ServiceSearchCommunityType
    v4Community: ServiceV4CommunityType
    user: ServiceUserType
    bizPost: ServiceSearchBizPostType
    recentSearch: ServiceRecentSearchType
    place: ServiceSearchPlaceType
    keywordNotification: ServiceKeywordNotificationType
  }
}

export function createContext({
  app,
  user,
  regionId,
  experiment,
}: {
  app: AppType
  user: UserType
  regionId?: number
  experiment: SearchExperimentReferrerType
}): MyContext {
  return {
    app,
    user,
    regionId,
    experiment,
    services: {
      v4Community: getServiceV4Community({ app, user }),
      hotKeywords: getServiceHotKeywords({
        app,
        user,
      }),
      karrotMarket: getServiceKarrotMarket({
        app,
        user,
      }),
      xProperty: getServiceXProperty({ app, user }),
      autoComplete: getServiceAutoComplete({ app, user }),
      category: getServiceCategory({ app, user }),
      aggregated: getServiceSearchAggregated({
        app,
        user,
      }),
      aggregatedPreProcess: getServiceAggregatedPreProcess({
        app,
        user,
      }),
      fleaMarket: getServiceSearchFleaMarket({ app, user }),
      v4Fleamarket: getServiceV4Fleamarket({ app, user }),
      community: getServiceSearchCommunity({
        app,
        user,
      }),
      user: getServiceUser({ app, user }),
      bizPost: getServiceSearchBizPost({ app, user }),
      recentSearch: getServiceRecentSearch({ app, user }),
      place: getServiceSearchPlace({ app, user }),
      keywordNotification: getServiceKeywordNotification({ app, user }),
    },
  }
}
