import type { SearchFleaMarketResolvers } from '../../__codegen__/__graphql__/resolvers'
import type { ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfPropertiesFleaMarketPriceFreeTextsInner } from '../../__codegen__/__openapi__/search-front-server'

export const SearchFleaMarket: SearchFleaMarketResolvers = {
  id(parent) {
    return `SearchFleaMarket#${parent.meta.docId}`
  },
  _id(parent) {
    return parent.source.id
  },
  categoryId(parent) {
    return parent.source.categoryId
  },
  // TODO: BFF로 이관
  async category(parent, args, ctx) {
    const response = await ctx.services.category.getFleaMarketCategoryList({
      referrer: { experiment: ctx.experiment },
    })

    const categories = (response?.categories ?? []).filter(Boolean)
    const category = categories.find(
      (category) => category.id === parent.source.categoryId.toString()
    )

    if (!category) {
      return null
    }
    return category
  },
  title(parent) {
    return parent.source.title ?? ''
  },
  regionName(parent) {
    return parent.fetch.regionName ?? ''
  },
  createdAt(parent) {
    if (!parent.source?.createdAt) {
      return
    }

    // NOTE: 통합탭 API 대응
    if (typeof parent.source.createdAt === 'string') {
      return new Date(parent.source.createdAt).toISOString()
    }

    if (!parent.source.createdAt.iso) {
      return
    }

    return parent.source.createdAt.iso
  },
  publishedAt(parent) {
    if (!parent.source?.publishedAt) {
      return
    }

    // NOTE: 통합탭 API 대응
    if (typeof parent.source.publishedAt === 'string') {
      return parent.source.publishedAt
    }

    if (!parent.source.publishedAt.iso) {
      return
    }

    return parent.source.publishedAt.iso
  },
  firstImage(parent) {
    return parent.fetch.firstImage
      ? {
          url: parent.fetch.firstImage.url,
          thumbnail: parent.fetch.firstImage.thumbnail,
          medium: parent.fetch.firstImage.medium,
        }
      : null
  },
  status(parent) {
    switch (parent.source.status) {
      case 'FLEA_MARKET_STATUS_ONGOING':
        return 'ONGOING'
      case 'FLEA_MARKET_STATUS_RESERVED':
        return 'RESERVED'
      case 'FLEA_MARKET_STATUS_CLOSED':
        return 'CLOSED'
      case 'FLEA_MARKET_STATUS_UNSPECIFIED':
      default:
        return 'UNKNOWN'
    }
  },
  price(parent) {
    return parent.source.price ?? null
  },
  chatRoomsCount(parent) {
    return parent.fetch.chatRoomCount ?? 0
  },
  watchesCount(parent) {
    return parent.fetch.watchCount ?? 0
  },
  republishCount(parent) {
    return parent.source.republishCount ?? 0
  },
  freeShareEventEnabled(parent) {
    return parent.source.freeShareEventEnabled ?? false
  },
  bidsCount(parent) {
    return parent.source.bidCount ?? 0
  },
  hyperLocalGroupName(parent) {
    return parent.fetch.hyperLocalGroupName ?? null
  },
  async sharingText(parent, args, ctx) {
    const { app } = ctx

    if (!app) {
      return null
    }

    const resp = await ctx.services.xProperty.getSharingTextByCategory()
    const payload = resp?.flea_market_price_free_texts

    if (!payload) {
      return null
    }

    // Array 크기가 1이고, 카테고리 아이디가 없으면, 키워드 전체 적용
    if (
      payload.length === 1 &&
      (payload[0].category_id === null || payload[0].category_id === undefined)
    ) {
      return getSharingTextFromPlatform(payload[0], app.os)
    }

    // 목록에서 맞는 카테고리를 찾아서 이름을 반환
    const matchedPayload = payload.find(
      (item) => item.category_id === Number(parent.source.categoryId)
    )

    if (matchedPayload) {
      return getSharingTextFromPlatform(matchedPayload, app.os)
    }

    return null
  },
}

const getSharingTextFromPlatform = (
  sharingTextItem: ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfPropertiesFleaMarketPriceFreeTextsInner,
  platform: 'ANDROID' | 'IOS' | 'UNKNOWN'
) => {
  if (platform === 'UNKNOWN') {
    return null
  }

  if (platform === 'ANDROID') {
    return sharingTextItem.android_text
  }

  return sharingTextItem.ios_text
}
