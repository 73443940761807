import { take } from 'lodash-es'
import type {
  InputMaybe,
  QueryResolvers,
  SearchV4CommunityFilterInputSort,
} from '../../__codegen__/__graphql__/resolvers'
import { COMMUNITY_FILTER_SORTS } from '../stores/communityFilterSortStore'
import { V4SearchSort as V4SearchCommunitySort } from '../../__codegen__/__openapi__/search-server/community/v4/community'

export const SearchV4CommunityQueries: QueryResolvers = {
  /**
   * 동네생활 검색 결과
   */
  async searchV4Communities(parent, args, ctx) {
    const first = args.first + 1

    const resp = await ctx.services.v4Community.getCommunities({
      query: args.data.query,
      regionId: args.data.regionId.toString(),
      pageSize: first,
      pageToken: args.after ?? undefined,
      sort: convertSortToCommunitySort(args.data.sort ?? undefined),
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to community search result')
    }

    return {
      edges: take(resp.connection.edges, args.first),
      pageInfo: {
        hasNextPage: resp.connection.edges.length > args.first,
        hasPreviousPage: false,
        startCursor: resp.connection.edges[0]?.cursor ?? null,
        endCursor: resp.connection.edges[args.first - 1]?.cursor ?? null,
      },
      experiment: resp.experiment,
      error: resp.error,
    }
  },
}

const convertSortToCommunitySort = (
  sort: InputMaybe<SearchV4CommunityFilterInputSort> | undefined
) => {
  switch (sort) {
    case COMMUNITY_FILTER_SORTS.RECENT.ID:
      return V4SearchCommunitySort.RECENT
    case COMMUNITY_FILTER_SORTS.RELEVANT.ID:
      return V4SearchCommunitySort.RELEVANT
    default:
      return V4SearchCommunitySort.UNSPECIFIED
  }
}
